import pressPayLogoSquare from 'media/presspay-logo-square.svg';
import pressPayLogoWide from 'media/presspay-logo-wide.svg';

interface NavLogoProps {
  type: 'square' | 'wide';
}

const Logo = (props: NavLogoProps): JSX.Element => {
  const { type } = props;
  if (type === 'square') {
    return <img height="50px" alt="PressPay" src={pressPayLogoSquare} />;
  }
  if (type === 'wide') {
    return <img height="50px" alt="PressPay" src={pressPayLogoWide} />;
  }
  return null;
};

export default Logo;
