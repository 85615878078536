/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { postForgotEmail } from 'lib/api';
import type { AuthState } from './index';

interface ForgotEmailProps {
  mobileNumber: string;
  recaptchaToken: string;
}

export const forgotEmail = createAsyncThunk(
  'auth/forgotEmail',
  async ({ mobileNumber, recaptchaToken }: ForgotEmailProps) => postForgotEmail(mobileNumber, recaptchaToken),
);

export const forgotEmailBuilder = (builder: ActionReducerMapBuilder<AuthState>): ActionReducerMapBuilder<AuthState> =>
  builder;
