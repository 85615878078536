/* eslint-disable @typescript-eslint/no-unsafe-return */
import { FetchArgs, retry } from '@reduxjs/toolkit/dist/query';
import { UnwrapPromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { TransactionRecord } from 'lib/models';
import { DebitCardRepaymentOption, DebitCardRepaymentSubmitData } from 'lib/models/debit-card-repayment';
import { RepaymentPlanInstallment, RepaymentPlanOption } from 'lib/models/repayment-plan-option';
import { get } from 'lodash';
import { userWebApi } from '..';

interface RepaymentPlanOptionErrorResponse {
  data: {
    errors: {
      code: string;
      meta: {
        code: string;
      };
      title: string;
    }[];
  };
}

export const userRepaymentApi = userWebApi.injectEndpoints({
  endpoints: (build) => ({
    getRepaymentPlanOptions: build.query<RepaymentPlanOption[] | string[], void>({
      query: () => ({
        url: '/repayment/plan/option',
        method: 'GET',
      }),
      transformResponse: (response: {
        data: {
          attributes: RepaymentPlanOption;
          relationships?: {
            installments?: { data: { id: string }[] };
          };
        }[];
        included?: { id: string; attributes: RepaymentPlanInstallment }[];
      }) =>
        response?.data?.map((d) => {
          const installmentIds = d.relationships?.installments?.data.map((i) => i.id);
          const installments = response.included
            ?.filter((i) => installmentIds?.includes(i.id))
            .map((i) => ({ id: i.id, ...i.attributes }));

          return { ...d.attributes, installments };
        }),
      transformErrorResponse: (response: unknown) => {
        const {
          data: { errors },
        } = response as RepaymentPlanOptionErrorResponse;
        return errors ? errors?.map((e) => e.meta.code) : [];
      },
    }),
    getDebitCardRepaymentOptions: build.query<DebitCardRepaymentOption[], void>({
      query: () => ({
        url: '/repayment/debit-card/option',
        method: 'GET',
      }),
      transformResponse: (response: {
        data: {
          attributes: DebitCardRepaymentOption;
        }[];
      }) => response?.data?.map(({ attributes: { amount, type } }) => ({ amount, type })),
      transformErrorResponse: (response: unknown) => {
        const {
          data: { errors },
        } = response as RepaymentPlanOptionErrorResponse; // TODO: Implement error handling for partial repayment options
        return errors ? errors?.map((e) => e.meta.code) : [];
      },
    }),
    postRepaymentPlan: build.mutation<unknown, RepaymentPlanOption>({
      queryFn: async (arg, api, _extra, baseQuery) => {
        const { data, error } = await baseQuery({
          url: '/repayment/plan',
          method: 'POST',
          body: { data: { attributes: arg } },
        });

        if (error) {
          return {
            error,
          };
        }

        const id = get(data, 'data.attributes.transactions[0].id') as string;
        const retryBaseQuery = retry(baseQuery, {
          maxRetries: 12,
          backoff: () => new Promise((resolve) => setTimeout(resolve, 5000)),
        });
        await retryBaseQuery({ url: `/user/transactions/${id}`, method: 'GET' }, api, undefined);
        return { data: undefined };
      },
      invalidatesTags: ['UserProfile'],
    }),
    postDebitCardRepayment: build.mutation({
      queryFn: async (arg: DebitCardRepaymentSubmitData, api, _extra, baseQuery) => {
        const { data: postData, error: postError } = await baseQuery({
          url: '/repayment/debit-card',
          method: 'POST',
          body: { data: { attributes: arg } },
        });

        if (postError) {
          return { error: postError };
        }

        const customBaseQuery = async (
          args: string | FetchArgs,
        ): Promise<UnwrapPromise<ReturnType<typeof baseQuery>>> => {
          const result = (await baseQuery(args)) as {
            data: {
              data?: {
                attributes?: {
                  status?: string;
                  verificationRequired?: boolean;
                  verificationUrl?: string;
                };
              };
            };
          };

          const { status, verificationRequired, verificationUrl } = result.data?.data?.attributes ?? {};

          if (status === 'confirmed' || status === 'failed') {
            return { data: { status } };
          }

          if (status === 'submitted' && verificationRequired && verificationUrl) {
            return { data: { status, verificationRequired, verificationUrl } };
          }

          if (status) {
            return {
              error: {
                data: status,
                error: 'NotExpectedStatusError',
                status: 'CUSTOM_ERROR' as const,
              },
            };
          }

          return result;
        };

        const retryBaseQuery = retry(customBaseQuery, {
          maxRetries: 10,
          backoff: () => new Promise((resolve) => setTimeout(resolve, 3000)),
        });

        const id = get(postData, 'data.id') as string;

        const finalResult = await retryBaseQuery(
          {
            url: `/user/transactions/${id}`,
            method: 'GET',
          },
          api,
          undefined,
        );

        return finalResult;
      },
      invalidatesTags: ['UserProfile'],
    }),
    getDebitCardRepaymentStatus: build.query<TransactionRecord, string>({
      queryFn: async (id: string, api, _extra, baseQuery) =>
        retry(
          async (args) => {
            const { data } = (await baseQuery(args)) as { data: { data: TransactionRecord } };
            const {
              data: { attributes },
            } = data;

            if (attributes.status !== 'confirmed' && attributes.status !== 'failed') {
              return {
                error: {
                  data: attributes.status,
                  error: 'NotExpectedStatusError',
                  status: 'CUSTOM_ERROR' as const,
                },
              };
            }

            return data;
          },
          {
            maxRetries: 10,
            backoff: () => new Promise((resolve) => setTimeout(resolve, 3000)),
          },
        )({ url: `/user/transactions/${id}`, method: 'GET' }, api, undefined) as Promise<{
          data: TransactionRecord;
        }>,
    }),
  }),
});
