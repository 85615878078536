import { Alert, AlertTitle, Button, Container, Link, Stack, Typography } from '@mui/material';
import Logo from 'components/logo/logo';
import { copy } from 'lib/copy';
import birds from 'media/tree-with-birds.svg';

interface FatalErrorProps {
  error: Error & { details?: string };
  resetErrorBoundary: () => void;
}

const FatalError = (props: FatalErrorProps): JSX.Element => {
  const { error, resetErrorBoundary } = props;

  return (
    <main className="main-wrapper">
      <Container disableGutters sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <Stack sx={{ flex: '1 1 auto', gap: 2, p: 2 }} alignItems="center">
          <Logo type="square" />
          <Typography textAlign="center" variant="h4">
            Whoops! looks like something has gone wrong.
          </Typography>
          <Typography textAlign="center">Usually waiting a moment and trying again resolves these issues.</Typography>
          <img src={birds} alt="perched cockatoos" height="320px" />
          <Button
            href="/"
            variant="contained"
            sx={{ backgroundColor: '#001E5A', color: 'white', borderRadius: '10rem' }}
            onClick={() => resetErrorBoundary()}
          >
            Start again
          </Button>
          <Typography fontSize="small" textAlign="center">
            If not, contact &nbsp;
            <Link href={copy.urls.zendesk} target="_blank">
              support@presspay.com
            </Link>
            &nbsp;for further assistance.
          </Typography>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            <Typography paragraph>{error.message}</Typography>
            <Typography paragraph>{error.details}</Typography>
          </Alert>
        </Stack>
      </Container>
    </main>
  );
};

export default FatalError;
