import { useAuthState } from 'lib/store/auth';
import type { FC, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const { authenticated } = useAuthState();
  const location = useLocation();

  if (!authenticated) {
    return <Navigate to="/auth/login" state={{ continuePath: location.pathname }} />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default AuthGuard;
