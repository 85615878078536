/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useAppSelector } from 'lib/hooks';
import { TransactionRecord } from 'lib/models/';
import { orderBy } from 'lodash';
import { fetchTransactions } from '../api';

interface TransactionState {
  records: TransactionRecord[];
  pageKey: string;
  status: 'ready' | 'pending' | 'failed' | 'refreshing';
}

const initialState: TransactionState = {
  records: [],
  pageKey: null,
  status: 'pending',
};

export const getTransactions = createAsyncThunk('transactions/getTransactions', () => fetchTransactions());

export const transactionsSlice = createSlice({
  name: 'transaction',
  reducers: {
    resetTransactionState: () => initialState,
  },
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getTransactions.pending, (state) => {
        state.status = 'refreshing';
      })
      .addCase(getTransactions.fulfilled, (state, action) => {
        const records = orderBy(action.payload, ['attributes.submitAt'], ['desc']);
        state.records = records;
        state.status = 'ready';
      })
      .addCase(getTransactions.rejected, (state) => {
        state.records = [];
        state.status = 'failed';
      });
  },
});

export const {
  actions: { resetTransactionState },
  reducer: transactionReducer,
} = transactionsSlice;

export const useTransactionState = (): TransactionState =>
  useAppSelector<TransactionState>((state) => state.transactions);
