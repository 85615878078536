import confused from 'media/stick-n-stone/confused.png';
import { Button, Card, CardContent, Stack, Typography } from '@mui/material';
import Logo from 'components/logo/logo';

const NotFound = (): JSX.Element => (
  <Card sx={{ flex: '1 1 auto', maxWidth: 448, mx: 'auto' }}>
    <CardContent sx={{ p: { xs: 2, sm: 4 } }}>
      <Stack sx={{ gap: 2 }} alignItems="center">
        <Logo type="square" />
        <Typography textAlign="center" variant="h4">
          Whoops!
        </Typography>
        <Typography>It looks like that page doesn&apos;t exist.</Typography>
        <img src={confused} alt="Confused" height="320px" />
        <Button href="/" variant="contained">
          Start again
        </Button>
      </Stack>
    </CardContent>
  </Card>
);

export default NotFound;
