import { Box, Container } from '@mui/material';
import { userApi } from 'api/user';
import { useAppDispatch } from 'lib/hooks';
import { refreshProfile, updateLoadingFlag } from 'lib/store/profile';
import type { FC, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './main-footer';
import MainNavbar from './main-navbar';
import MainSidebar from './main-sidebar';

interface MainLayoutProps {
  children?: ReactNode;
}

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);
  const { data, isLoading, isError, isSuccess } = userApi.useGetProfileQuery();

  useEffect(() => {
    if (isSuccess) {
      void dispatch(refreshProfile(data));
    } else if (isLoading) {
      void dispatch(updateLoadingFlag(true));
    } else if (isError) {
      void dispatch(updateLoadingFlag(false));
    }
  }, [dispatch, data, isLoading, isError, isSuccess]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <MainNavbar onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)} />
      <MainSidebar onMobileClose={(): void => setIsSidebarMobileOpen(false)} openMobile={isSidebarMobileOpen} />
      <Container
        disableGutters
        sx={{ display: { xs: 'flex', sm: 'block' }, flex: '1 0 auto', pt: '64px' }}
        maxWidth="md"
      >
        {children || <Outlet />}
      </Container>
      <Footer />
    </Box>
  );
};

export default MainLayout;
