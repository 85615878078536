export const employmentType = {
  fullTime: 'Full time',
  partTime: 'Part time',
  casual: 'Casual',
};

export type EmploymentType = keyof typeof employmentType;

export const incomeFrequency = {
  fortnightly: 'Fortnightly',
  monthly: 'Monthly',
  weekly: 'Weekly',
  'four-weekly': 'Four weekly',
};

export type IncomeFrequency = keyof typeof incomeFrequency;

export interface Income {
  employerPayDescription: string;
  source: EmploymentType;
  frequency: IncomeFrequency;
  lastAmount: number;
  lastDate: string;
}
