import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Button, Container, Divider, IconButton, Link, Menu, MenuItem, Toolbar } from '@mui/material';
import { Auth } from 'aws-amplify';
import { ShopFeatureFlag } from 'components/feature-flag-user-group';
import Logo from 'components/logo/logo';
import { useAppDispatch } from 'lib/hooks';
import { resetApp } from 'lib/store';
import { setContinuePath, useAuthState } from 'lib/store/auth';
import { useProfileState } from 'lib/store/profile';
import advancedLogo from 'media/advanced-logo-primary.svg';
import pressPayLogo from 'media/presspay-logo-wide.svg';
import shopLogo from 'media/shop-logo-primary.svg';
import { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface MainNavbarProps {
  onSidebarMobileOpen?: () => void;
}

const MainNavbar: FC<MainNavbarProps> = (props) => {
  const { onSidebarMobileOpen } = props;
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { authenticated } = useAuthState();
  const { canWithdraw } = useProfileState();
  const location = useLocation();

  const getLogoForPath = (path: string) =>
    path.startsWith('/shop/') ? shopLogo : path.startsWith('/withdraw') ? advancedLogo : pressPayLogo;

  const logo = getLogoForPath(location.pathname);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () =>
    Auth.signOut().finally(() => {
      dispatch(setContinuePath('/'));
      dispatch(resetApp());
    });

  return (
    <AppBar elevation={0} sx={{ backgroundColor: 'background.paper', color: 'text.secondary' }}>
      <Container disableGutters maxWidth="md">
        <Toolbar disableGutters sx={{ minHeight: 64 }}>
          <IconButton color="inherit" onClick={onSidebarMobileOpen} sx={{ display: { md: 'none' } }}>
            <MenuIcon fontSize="small" />
          </IconButton>
          <Button href="/" sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Logo type="square" />
          </Button>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Button color="inherit" href="/" sx={{ my: 2, display: 'block' }}>
              Dashboard
            </Button>
            {canWithdraw && (
              <Button color="inherit" href="/withdraw" sx={{ my: 2, display: 'block' }}>
                Withdraw
              </Button>
            )}
            <ShopFeatureFlag>
              <Button color="inherit" href="/shop/browse" sx={{ my: 2, display: 'block' }}>
                Shop
              </Button>
            </ShopFeatureFlag>
            <Button color="inherit" href="/repayment" sx={{ my: 2, display: 'block' }}>
              Repayment
            </Button>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' }, flexGrow: 1, justifyContent: 'center' }}>
            <img height="30px" alt="PressPay" src={logo} />
          </Box>
          {authenticated && (
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircleIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem>
                  <Link color="inherit" underline="none" href="/profile">
                    My profile
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link color="inherit" underline="none" href="/bank-data">
                    Reconnect bank account
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link color="inherit" underline="none" href="/employment-details">
                    Update employment details
                  </Link>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => void handleSignOut()}>Sign out</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default MainNavbar;
