import DashboardIcon from '@mui/icons-material/Dashboard';
import PaidIcon from '@mui/icons-material/Paid';
import PaymentIcon from '@mui/icons-material/Payment';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ShopFeatureFlag } from 'components/feature-flag-user-group';
import Logo from 'components/logo/logo';
import { useProfileState } from 'lib/store/profile';
import type { FC } from 'react';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

interface MainSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const MainSidebar: FC<MainSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const { canWithdraw } = useProfileState();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={!lgUp && openMobile}
      variant="temporary"
      PaperProps={{ sx: { backgroundColor: 'background.default', width: 256 } }}
    >
      <Box
        sx={{
          alignItems: 'stretch',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: 'auto',
          p: 2,
        }}
      >
        <RouterLink to="/">
          <Logo type="square" />
        </RouterLink>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton href="/">
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton>
          </ListItem>
          {canWithdraw && (
            <ListItem disablePadding>
              <ListItemButton href="/withdraw">
                <ListItemIcon>
                  <PaidIcon />
                </ListItemIcon>
                <ListItemText primary="Withdraw" />
              </ListItemButton>
            </ListItem>
          )}
          <ShopFeatureFlag>
            <ListItem disablePadding>
              <ListItemButton href="/shop/browse">
                <ListItemIcon>
                  <ShoppingCartOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Shop" />
              </ListItemButton>
            </ListItem>
          </ShopFeatureFlag>
          <ListItem disablePadding>
            <ListItemButton href="/repayment">
              <ListItemIcon>
                <PaymentIcon />
              </ListItemIcon>
              <ListItemText primary="Repayment" />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

export default MainSidebar;
