/* eslint-disable @typescript-eslint/no-unsafe-return */
import { FetchArgs, retry } from '@reduxjs/toolkit/dist/query';
import { UnwrapPromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { get } from 'lodash';
import { userWebApi } from '..';
import { Transaction } from '../../lib/models';

export const userTransactionApi = userWebApi.injectEndpoints({
  endpoints: (build) => ({
    getTransaction: build.query<Transaction, string>({
      query: (id) => {
        return {
          url: `/user/transactions/${id}`,
          method: 'GET',
        };
      },
      transformResponse: (response: { data: { id: string; attributes: Transaction }[] }) => ({
        id: response?.data[0]?.id,
        ...response?.data[0]?.attributes,
      }),
    }),
    getTransactions: build.query<Transaction[], { sort: string }>({
      query: ({ sort }) => {
        return {
          url: `/user/transactions?sort=${sort}`,
          method: 'GET',
        };
      },
      transformResponse: (response: { data: { id: string; attributes: Transaction }[] }) =>
        response?.data?.map((d) => ({ id: d.id, ...d.attributes })),
    }),
    postTransactions: build.mutation({
      queryFn: async (arg: { amount: number }, api, _extra, baseQuery) => {
        const { data: postData, error: postError } = await baseQuery({
          url: '/user/transactions',
          method: 'POST',
          body: {
            data: {
              attributes: arg,
            },
          },
        });

        if (postError) {
          return {
            error: postError,
          };
        }

        const customBaseQuery = async (
          args: string | FetchArgs,
        ): Promise<UnwrapPromise<ReturnType<typeof baseQuery>>> => {
          const result = (await baseQuery(args)) as {
            data: {
              data?: {
                attributes?: {
                  status?: string | undefined;
                };
              };
            };
          };

          const status = result.data?.data?.attributes?.status;

          if (status === 'confirmed' || status === 'failed') {
            return { data: { status } };
          }

          if (status) {
            return { error: { status: 'CUSTOM_ERROR' as const, data: status, error: 'notExpectedStatusError' } };
          }

          return result;
        };

        const retryBaseQuery = retry(customBaseQuery, {
          maxRetries: 15,
          backoff: () => new Promise((resolve) => setTimeout(resolve, 2000)),
        });

        const id = get(postData, 'data.id') as string;

        const finalResult = await retryBaseQuery(
          {
            url: `/user/transactions/${id}`,
            method: 'GET',
          },
          api,
          undefined,
        );

        return finalResult;
      },
      invalidatesTags: ['UserProfile'],
    }),
  }),
});
