import { Box, Card, CardActionArea, CardMedia, Chip, ChipTypeMap, SxProps, Theme, Typography } from '@mui/material';
import { CardProduct, activationDelayDurationInHours } from 'lib/models/card-product';
import storeCardSvg from 'media/shop-store-card.svg';
import { ReactNode } from 'react';

const CardProductFeeBadge = (props: {
  children?: ReactNode;
  chipProps?: ChipTypeMap['props'];
  sx?: SxProps<Theme>;
}): JSX.Element => {
  const { chipProps, children, sx } = props;

  return (
    <Chip
      sx={{
        backgroundColor: 'secondary.main',
        color: 'common.white',
        position: 'absolute',
        right: 8,
        top: 8,
        ...sx,
      }}
      label={children}
      {...chipProps}
    />
  );
};

const CardProductBottomBanner = (props: { children?: ReactNode; sx?: SxProps<Theme> }): JSX.Element => {
  return (
    <Box
      sx={{
        backgroundColor: 'secondary.main',
        bottom: 0,
        position: 'absolute',
        textAlign: 'center',
        width: '100%',
        ...props.sx,
      }}
    >
      {props.children}
    </Box>
  );
};

interface CardProductCardProps
  extends Partial<
    Pick<CardProduct, 'activationDelayDuration' | 'feePercent' | 'productImageUrl' | 'displayName' | 'status'>
  > {
  actionUrl?: string;
  children?: ReactNode;
  size?: 'small' | 'medium';
  sx?: SxProps<Theme>;
}

export const CardProductCard = (props: CardProductCardProps): JSX.Element => {
  const feePercentText = props.feePercent > 0 ? `${props.feePercent}% fee` : `Zero fees`;
  const size = props.size || 'medium';
  const statusUnavailable = props.status === 'unavailable';
  const statusUnavailableSx = statusUnavailable ? { filter: 'opacity(50%)' } : undefined;

  return (
    <Card sx={{ aspectRatio: 1.586, borderRadius: '1rem', position: 'relative', ...props.sx }}>
      <CardActionArea href={props.actionUrl} sx={{ height: '100%' }}>
        <CardMedia
          component="img"
          image={props.productImageUrl || storeCardSvg}
          sx={{ height: '100%', objectFit: 'contain', transform: 'scale(1.1)', ...statusUnavailableSx }}
        />
        {statusUnavailable ? (
          <CardProductBottomBanner sx={{ backgroundColor: 'grey.800' }}>
            <Typography color="common.white" variant="subtitle1">
              Unavailable
            </Typography>
          </CardProductBottomBanner>
        ) : (
          <>
            {isFinite(props.feePercent) &&
              (props.feePercent > 0 ? (
                <CardProductFeeBadge chipProps={{ size }}>{feePercentText}</CardProductFeeBadge>
              ) : (
                <CardProductFeeBadge chipProps={{ size }} sx={{ backgroundColor: 'pp.gold', color: 'secondary.main' }}>
                  {feePercentText}
                </CardProductFeeBadge>
              ))}
            {props.activationDelayDuration && (
              <CardProductBottomBanner>
                <Typography
                  color="common.white"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  variant="subtitle1"
                  whiteSpace="nowrap"
                >
                  {activationDelayDurationInHours(props.activationDelayDuration)}hr delay to use
                </Typography>
              </CardProductBottomBanner>
            )}
          </>
        )}
      </CardActionArea>
    </Card>
  );
};
