import { useAuthState } from 'lib/store/auth';
import type { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

interface GuestGuardProps {
  children: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { authenticated, continuePath = '/' } = useAuthState();

  if (authenticated) {
    return <Navigate to={continuePath} />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default GuestGuard;
