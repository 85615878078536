import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const ShopFeatureFlag = (props: { redirectOnUnauthorized?: boolean; children?: ReactNode }): JSX.Element => {
  const navigate = useNavigate();
  const shopEnabled = process.env.REACT_APP_SHOP_FEATURE_FLAG_ENABLED === 'true';

  useEffect(() => {
    if (!shopEnabled && props.redirectOnUnauthorized) {
      navigate('/not-found');
    }
  }, [navigate, props.redirectOnUnauthorized, shopEnabled]);

  if (shopEnabled) {
    return <>{props.children}</>;
  }
};

export const RepaymentPlanFeatureFlag = (props: {
  redirectOnUnauthorized?: boolean;
  children?: ReactNode;
}): JSX.Element => {
  const navigate = useNavigate();
  const repaymentPlanEnabled = process.env.REACT_APP_REPAYMENT_PLAN_FEATURE_FLAG_ENABLED === 'true';

  useEffect(() => {
    if (!repaymentPlanEnabled && props.redirectOnUnauthorized) {
      navigate('/not-found');
    }
  }, [navigate, props.redirectOnUnauthorized, repaymentPlanEnabled]);

  if (repaymentPlanEnabled) {
    return <>{props.children}</>;
  }
};
