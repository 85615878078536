import { Button, InputProps, Link, Stack, TextField } from '@mui/material';
import { Auth } from 'aws-amplify';
import ButtonSpinner from 'components/button-spinner';
import { useFormik } from 'formik';
import { useAppDispatch } from 'lib/hooks';
import { resetAuth, setCanChangePassword, verifyEmail } from 'lib/store/auth';
import { debounce } from 'lodash';
import { useCallback, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

const UserIdentifierWidget = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [emailValid, setEmailValid] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const validationSchema = yup.object({
    email: yup.string().email('Enter a valid email').required('Email is required'),
    password: yup.string().when((_, schema) => (emailValid ? schema.required('Password is required') : schema)),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (values, helper) => {
      const { email, password } = values;
      try {
        if (emailValid) {
          await Auth.signIn(email, password)
            .then(() => dispatch(setCanChangePassword(true)))
            .catch((err) => helper.setFieldError('password', err.message || 'Incorrect password, please try again'));
        } else {
          const recaptchaToken = await executeRecaptcha();
          const account = await dispatch(verifyEmail({ email: values.email, recaptchaToken })).unwrap();

          if (account.accountCreated) {
            // First-time login, set new password
            navigate(`/auth/password-reset`, { state: { email: values.email } });
          } else if (account.accountExists) {
            setEmailValid(true);
          } else {
            helper.setFieldError('email', "Couldn't find your PressPay account");
          }
        }
      } catch (err) {
        helper.setFieldError('email', "Couldn't verify your email address, please try again later");
      }
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resetAuthThrottled = useCallback(
    debounce(
      () => {
        setEmailValid(false);
        dispatch(resetAuth());
        void formik.setFieldValue('password', '');
      },
      3000,
      { leading: true, trailing: false },
    ),
    [dispatch],
  );

  const handleEmailChanged: InputProps['onChange'] = (event) => {
    void formik.setFieldValue('email', event.target.value);
    resetAuthThrottled();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack sx={{ gap: 2 }}>
        <TextField
          disabled={formik.isSubmitting}
          id="email"
          name="email"
          label="Email"
          type="email"
          value={formik.values.email}
          onChange={handleEmailChanged}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          fullWidth
        />

        {emailValid ? (
          <>
            <TextField
              autoFocus
              disabled={formik.isSubmitting}
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <Link
              color="secondary"
              underline="none"
              href={`/auth/password-recovery?email=${encodeURIComponent(formik.values.email)}`}
            >
              Forgot password?
            </Link>
          </>
        ) : (
          <Link color="secondary" underline="none" href="/auth/email-recovery">
            Forgot email?
          </Link>
        )}

        <Stack direction="row" justifyContent="flex-end" sx={{ gap: 2 }}>
          <Button color="secondary" href="https://sign-up.presspay.com.au">
            Sign up
          </Button>
          <Button variant="contained" type="submit">
            Next <ButtonSpinner loading={formik.isSubmitting} />
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default UserIdentifierWidget;
