import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import * as Sentry from '@sentry/react';
import { configureAuth } from 'auth';
import FatalError from 'components/fatal-error';
import { persistor, resetApp, store } from 'lib/store';
import { StrictMode, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import theme from 'theme';
import 'theme/global.scss';
import App from './app';
import './firebase';
import './index.css';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', process.env.REACT_APP_API_URL],

  // Capture Replay for 10% of all sessions. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysSessionSampleRate: 0.1,

  // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  replaysOnErrorSampleRate: 1.0,
});

configureAuth(store);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <StrictMode>
    <ErrorBoundary FallbackComponent={FatalError} onReset={() => store.dispatch(resetApp())}>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <HelmetProvider>
                <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTURE_SITE_KEY} useEnterprise>
                  <App />
                </GoogleReCaptchaProvider>
              </HelmetProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </PersistGate>
      </ReduxProvider>
    </ErrorBoundary>
  </StrictMode>,
);
