/* eslint-disable @typescript-eslint/no-unsafe-return */
import { UserAttributes } from 'lib/models';
import { Income } from 'lib/models/income';
import { type PrimaryBankAccountData, type ProfileChangeState } from 'lib/models/profile-change-state';
import { userWebApi } from '..';
import { BankSession } from '../../lib/models/bank-session';
import { VerificationRequest, VerificationResult } from '../../lib/models/verification';

export { userRepaymentApi } from './repayment';
export { userTransactionApi } from './transaction';

interface ErrorResponse {
  data: {
    errors: {
      code: string;
    }[];
  };
}

export const userApi = userWebApi.injectEndpoints({
  endpoints: (build) => ({
    verification: build.mutation<VerificationResult, VerificationRequest>({
      query: (verification) => {
        return {
          url: '/user/verification',
          method: 'POST',
          body: { data: { attributes: verification } },
        };
      },
      transformResponse: (response: { data: { id: string; attributes: VerificationResult } }) => ({
        id: response?.data?.id,
        ...response?.data?.attributes,
      }),
    }),
    getProfile: build.query<UserAttributes, void>({
      query: () => ({
        url: '/user/account',
        method: 'GET',
      }),
      providesTags: ['UserProfile'],
      transformResponse: (response: {
        data: {
          attributes: UserAttributes;
        };
      }) => {
        return response.data.attributes;
      },
      transformErrorResponse: (response: unknown) => {
        const {
          data: { errors },
        } = response as ErrorResponse;
        return errors ? errors.map((e) => e.code) : [];
      },
    }),
    getIncome: build.query<Income, void>({
      query: () => ({
        url: '/user/income',
        method: 'GET',
      }),
      transformResponse: (response: {
        data: {
          attributes: Income;
        };
      }) => {
        return response.data.attributes;
      },
      transformErrorResponse: (response: unknown) => {
        const {
          data: { errors },
        } = response as ErrorResponse;
        return errors ? errors.map((e) => e.code) : [];
      },
    }),
    getProfileChangeState: build.query<ProfileChangeState, void>({
      query: () => ({
        url: '/user/profile-change-state',
        method: 'GET',
      }),
      transformResponse: (response: {
        data: {
          attributes: ProfileChangeState;
        };
      }) => {
        return response.data.attributes;
      },
      transformErrorResponse: (response: unknown) => {
        const {
          data: { errors },
        } = response as ErrorResponse;
        return errors ? errors.map((e) => e.code) : [];
      },
    }),
    postEmploymentDetails: build.mutation<void, { income: Partial<Income>; bankAccount: PrimaryBankAccountData }>({
      query: ({ income, bankAccount }) => ({
        url: `/user/employment-details`,
        method: 'PATCH',
        body: {
          data: {
            attributes: {
              income,
              bankAccount,
            },
          },
        },
      }),
    }),
    getBankSession: build.query<BankSession, void>({
      query: () => ({
        url: '/user/bank-session',
        method: 'GET',
      }),
      transformResponse: (response: {
        data: {
          attributes: BankSession;
        };
      }) => {
        return response.data.attributes;
      },
      transformErrorResponse: (response: unknown) => {
        const {
          data: { errors },
        } = response as ErrorResponse;
        return errors ? errors.map((e) => e.code) : [];
      },
    }),
    updateBankData: build.mutation<void, void>({
      query: () => {
        return {
          url: '/user/bank-data',
          method: 'POST',
        };
      },
    }),
  }),
});
