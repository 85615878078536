import { Amplify, Hub } from 'aws-amplify';
import { AppDispatch, resetApp } from 'lib/store';
import { authenticated, getAccessToken, setContinuePath } from 'lib/store/auth';

const config = {
  aws_cognito_region: process.env.REACT_APP_COGNITO_REGION,
  aws_project_region: process.env.REACT_APP_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_AMPLIFY_DOMAIN,
    scope: ['email', 'openid'],
    redirectSignIn: process.env.REACT_APP_AMPLIFY_REDIRECT_SIGN_IN,
    redirectSignOut: process.env.REACT_APP_AMPLIFY_REDIRECT_SIGN_OUT,
    responseType: 'code',
  },
};

/**
 * Configures the authentication event handler using Amplify
 */
export const configureAuth = ({ dispatch }: { dispatch: AppDispatch }): void => {
  Amplify.configure(config);

  Hub.listen('auth', ({ payload }) =>
    // Use timeout with no interval to push execution to end of stack
    // This gives the component hooks time to register to the dispatched event
    setTimeout(() => {
      const { event, data } = payload;

      switch (event) {
        case 'signIn': {
          try {
            dispatch(authenticated(JSON.parse(JSON.stringify(data))));
          } catch (err) {
            // Not valid JSON data, ignore.
          }
          break;
        }
        case 'tokenRefresh':
          void dispatch(getAccessToken());
          break;
        case 'oAuthSignOut':
        case 'signIn_failure':
        case 'signOut':
        case 'tokenRefresh_failure':
          dispatch(resetApp());
          break;
        case 'customOAuthState':
          try {
            const json = JSON.parse(data);
            dispatch(setContinuePath(json.continuePath));
          } catch (err) {
            // We tried
          }
          break;
      }
    }),
  );
};
