/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Auth } from 'aws-amplify';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../lib/config';
import { CognitoUser } from '@aws-amplify/auth';

export const userWebApi = createApi({
  reducerPath: 'user-web-api',
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_URL,
    prepareHeaders: async (headers) => {
      const user = (await Auth.currentAuthenticatedUser().catch(() => undefined)) as CognitoUser;
      if (user) {
        headers.set('Authorization', `Bearer ${user.getSignInUserSession()?.getIdToken()?.getJwtToken()}`);
      }
      return headers;
    },
  }),
  tagTypes: ['UserProfile'],
  endpoints: () => ({}),
});
