import { Button, DialogActions, Stack, Typography, useMediaQuery } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled, useTheme } from '@mui/material/styles';
import * as React from 'react';

const ContentDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const ShopDialogTitle = (props: DialogTitleProps): JSX.Element => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, color: 'common.white', backgroundColor: 'secondary.main' }} {...other}>
      {children}
    </DialogTitle>
  );
};

export interface ShopTransactionDialogProps {
  title: string;
  content?: string;
  iFrameUrl?: string;
  open: boolean;
  onClose: () => void;
}

export const ShopDialog = (props: ShopTransactionDialogProps): JSX.Element => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ContentDialog onClose={props.onClose} open={props.open} fullScreen={fullScreen}>
      <ShopDialogTitle id="customized-dialog-title" onClose={props.onClose}>
        {props.title}
      </ShopDialogTitle>
      <DialogContent dividers>
        {props.iFrameUrl ? (
          <iframe title={props.title} src={props.iFrameUrl} style={{ width: '100%', height: '100%' }} />
        ) : (
          props.content && (
            <Stack>
              <Typography
                dangerouslySetInnerHTML={{ __html: props.content }}
                sx={{ whiteSpace: 'pre-wrap' }}
                variant="body1"
              />
            </Stack>
          )
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button autoFocus onClick={props.onClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </ContentDialog>
  );
};
