/* eslint-disable no-param-reassign */
import { Auth } from 'aws-amplify';
import axios, { AxiosInstance, isAxiosError } from 'axios';
import errcode from 'err-code';
import { get, pick } from 'lodash';
import config from './config';

const apiUrl = config.API_URL;

const createAPIClient = (): AxiosInstance => {
  try {
    const client = axios.create({
      baseURL: apiUrl,
      withCredentials: false,
      transitional: { clarifyTimeoutError: true },
    });

    client.interceptors.request.use(async (req) => {
      const user = await Auth.currentAuthenticatedUser().catch(() => undefined);
      if (user) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        req.headers.authorization = `Bearer ${user.signInUserSession.idToken.jwtToken}`;
      }

      if (req.method === 'post') {
        req.data = { data: { attributes: { ...req.data } } };
      }
      return req;
    });

    // All JSON API error handling is done here. Convert JSON API Errors to errcode errors
    client.interceptors.response.use(
      (response) => response,
      (err) => {
        if (isAxiosError(err)) {
          const detail = pick(err, ['code', 'config.baseURL', 'config.method', 'config.url']);
          if (err.code === 'ETIMEDOUT') {
            return Promise.reject(errcode(new Error('API Timeout'), 'ApiTimeout', { detail }));
          }
          if (err.code === 'ERR_CONNECTION_REFUSED') {
            return Promise.reject(errcode(new Error('API Unavailable'), 'ApiUnavailable', { detail }));
          }
          if (err.code === 'ERR_NETWORK') {
            return Promise.reject(errcode(new Error('API Unavailable'), 'ApiUnavailable', { detail }));
          }
          if (err.code === 'ERR_BAD_REQUEST') {
            return Promise.reject(errcode(new Error('API Unavailable'), 'ApiUnavailable', { detail }));
          }
          const apiError = get(err, 'response.data.errors[0]', { code: 'UnknownApiError', title: '', detail: {} });

          return Promise.reject(
            errcode(new Error('API Error'), apiError.code || 'UnknownError', {
              detail: apiError.detail,
              message: err.message,
              title: apiError.title,
            }),
          );
        }
        return Promise.reject(err);
      },
    );

    return client;
  } catch (error) {
    throw errcode(new Error('API Client creation failed'), 'CreateApiClientFailed', { detail: error.message });
  }
};

const apiClient = createAPIClient();

export default apiClient;
