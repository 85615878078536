import { round } from 'lodash';
import { DateTime, Duration } from 'luxon';

export const CardProductStatuses = ['available', 'discontinued', 'new', 'unavailable'] as const;

export type CardProductStatus = (typeof CardProductStatuses)[number];

export interface CardProduct {
  id: string;
  activationDelayDuration?: string;
  denominationAvailable: number[];
  displayDescription?: string;
  displayName?: string;
  feePercent: number;
  merchantEmail?: string;
  merchantImageUrl?: string;
  merchantName: string;
  merchantPhone?: string;
  merchantUrl?: string;
  productDescription?: string;
  productExpAt?: string;
  productExpMonths?: number;
  productImageUrl?: string;
  productName: string;
  productRedemptionInfo?: string;
  productTnCText?: string;
  productTnCType?: string;
  productTnCUrl?: string;
  sourceId: string;
  status: CardProductStatus;
  type?: string;
}

export const cardProductName = (cardProduct: CardProduct): string =>
  cardProduct?.displayName || cardProduct?.merchantName || cardProduct?.productName || 'Shop card';

export const cardProductExpiry = (cardProduct: CardProduct): string =>
  (cardProduct?.productExpAt && DateTime.fromISO(cardProduct.productExpAt).toLocaleString(DateTime.DATE_MED)) ||
  (cardProduct?.productExpMonths && `${cardProduct.productExpMonths} months`) ||
  '3 years minimum';

export const formatCentsToDollars = (cents: number): string => {
  return `$${round(cents / 100)}`;
};

export const integerPercentToDecimal = (percent: number): number => {
  return round(percent / 100, 2);
};

export const activationDelayDurationInHours = (activationDelayDuration?: string): number | undefined => {
  try {
    return activationDelayDuration ? Duration.fromISO(activationDelayDuration).as('hours') : undefined;
  } catch {
    return undefined;
  }
};

export const isCardActive = (type: string): boolean => {
  const regex = /\bactive\b/;
  return regex.test(type);
};
