/* eslint-disable no-control-regex */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Link,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { errorsFromResponse, isFetchError, mapErrorCodeToMessage } from 'lib/error';
import { useGetCardProductQuery } from 'api/shop';
import { CardProductCard } from 'components/card-product-card';
import { DelayedActivationText } from 'components/delayed-activation-text';
import { ShopDialog } from 'components/shop-dialog';
import { useFormik } from 'formik';
import useToast from 'hooks/use-toast';
import { copy } from 'lib/copy';
import {
  cardProductInActiveErrorToastProps,
  canWithdrawErrorToastProps,
  mapErrorToToastProps,
} from 'lib/error-toast-props';
import { cardProductName, formatCentsToDollars, integerPercentToDecimal, isCardActive } from 'lib/models/card-product';
import { useProfileState } from 'lib/store/profile';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { ActionFunctionArgs, useActionData, useNavigate, useParams, useSubmit } from 'react-router-dom';
import * as yup from 'yup';
import { DateTime } from 'luxon';
import { isCreditAvailableForAmount } from '../../lib/models/user';

const BulletListItem = ({ children }: { children: ReactNode }): JSX.Element => (
  <ListItem
    disablePadding
    alignItems="flex-start"
    sx={{
      display: 'list-item',
    }}
  >
    <ListItemText primaryTypographyProps={{ variant: 'body1' }} primary={children} />
  </ListItem>
);

interface OrderData {
  cardProductId: string;
  amount: string;
  feePercent: string;
}

export const buyCardAction = async ({ request }: ActionFunctionArgs): Promise<OrderData> => {
  const formData = await request.formData();
  const orderData = Object.fromEntries(formData) as unknown as OrderData;
  return orderData;
};

const CardProduct = (): JSX.Element => {
  const { cardProductId } = useParams();
  const { data: profileData, isLoading: isProfileLoading, canWithdraw } = useProfileState();
  const {
    data: cardProduct,
    isLoading: cardProductLoading,
    error: cardProductError,
  } = useGetCardProductQuery(cardProductId, {
    refetchOnMountOrArgChange: true,
  });
  const productAvailable = cardProduct?.status === 'available';
  const productDescription = cardProduct?.productDescription ? cardProduct?.productDescription : '';
  const productRedemptionInfo = cardProduct?.productRedemptionInfo ? cardProduct?.productRedemptionInfo : '';
  const isLoading = isProfileLoading || cardProductLoading;
  const denominationAvailable = cardProduct && cardProduct.denominationAvailable.length > 0;
  const isActive = isCardActive(cardProduct?.type);

  const [dialogProps, setDialogProps] = useState<{ open: boolean; content: string }>({ open: false, content: '' });
  const handleDialogOpen = useCallback((content: string) => setDialogProps({ open: true, content }), []);
  const handleDialogClose = useCallback(() => setDialogProps({ open: false, content: '' }), []);

  const actionData = useActionData();
  const navigate = useNavigate();
  useEffect(() => {
    if (actionData) {
      navigate('/shop/order/create', { state: actionData });
    }
  }, [navigate, actionData]);

  const submit = useSubmit();
  const formik = useFormik({
    initialValues: {
      amount: '',
    },
    validationSchema: yup.object({
      amount: yup.string().required('Please select an amount value.'),
    }),
    onSubmit: (values) => {
      const { amount } = values;
      const { incomeNextDate } = profileData;
      if (!isCreditAvailableForAmount(profileData, Number(amount))) {
        formik.setFieldError(
          'amount',
          mapErrorCodeToMessage('MaxBalanceCreditError', {
            incomeNextDate: DateTime.fromISO(incomeNextDate).toLocaleString(DateTime.DATE_MED),
          }),
        );
        return;
      }

      const formData = new FormData();
      formData.append('cardProductId', cardProduct.id);
      formData.append('amount', values.amount);
      formData.append('feePercent', cardProduct.feePercent.toString());
      submit(formData, { method: 'POST' });
    },
  });

  useEffect(() => {
    if (!cardProductLoading && !denominationAvailable) {
      formik.setFieldError('amount', 'Insufficient balance');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [denominationAvailable, cardProductLoading]);

  // Error handling
  const [Toast, openToast, closeToast] = useToast({
    isOpen: false,
  });

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (isFetchError(cardProductError)) {
      const errorConfig = mapErrorToToastProps(cardProductError.status, errorsFromResponse(cardProductError));
      openToast(errorConfig);
      return;
    }

    if (!isActive) {
      openToast(cardProductInActiveErrorToastProps);
      return;
    }

    if (!canWithdraw) {
      openToast(canWithdrawErrorToastProps(closeToast));
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, canWithdraw, isLoading, cardProductError]);

  // Card Product Error handlers.
  if (isFetchError(cardProductError) && cardProductError.status === 404) {
    return (
      <Stack sx={{ flex: '1 1 auto', gap: 4, my: { sm: 4 } }}>
        <Card sx={{ flex: '1 1 auto', maxWidth: 448, mx: 'auto' }}>
          <CardContent sx={{ p: { xs: 2, sm: 4 } }}>
            <Stack sx={{ gap: 2 }} alignItems="center">
              <Typography textAlign="center" variant="h4">
                Card unavailable
              </Typography>
              <Typography textAlign="center">
                It appears that the card you&apos;re looking for isn&apos;t available at the moment. This could be due
                to the card being out of stock or the merchant no longer offering it. Please consider searching for the
                card again in the catalogue.
              </Typography>
              <Button href={'/shop/browse'} variant="contained">
                Browse cards
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    );
  }

  return (
    <Stack sx={{ flex: '1 1 auto', gap: 4, my: { sm: 4 } }}>
      <Card id="card-product">
        <Stack sx={{ p: 2, backgroundColor: 'secondary.main', color: 'common.white' }}>
          <Typography variant="h5" textAlign={'center'} paddingBottom={2}>
            Buy
          </Typography>
          <Typography variant="overline" color="common.white" textAlign={'center'}>
            {isLoading ? (
              <Skeleton sx={{ bgcolor: 'grey.400' }} />
            ) : canWithdraw ? (
              `Available balance: $${profileData.balanceAvailable}`
            ) : (
              `STATUS: ${copy.accountStatus[profileData.status]}`
            )}
          </Typography>
        </Stack>
        <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
          <Stack sx={{ px: 2 }}>
            <Typography color="secondary" variant="h4" textAlign={'center'} py={2}>
              {isLoading ? <Skeleton /> : cardProductName(cardProduct)}
            </Typography>
            <Box alignItems="center" display="flex" justifyContent="center">
              <CardProductCard
                productImageUrl={cardProduct?.productImageUrl || ''}
                activationDelayDuration={cardProduct?.activationDelayDuration}
                feePercent={integerPercentToDecimal(cardProduct?.feePercent)}
                status={cardProduct?.status}
                sx={{ marginBottom: 2, width: { xs: '100%', sm: 400 } }}
              />
            </Box>
            {canWithdraw && (
              <form method="post" onSubmit={formik.handleSubmit}>
                {(isLoading || productAvailable) && (
                  <>
                    <Grid container spacing={2} py={2} alignContent="center" justifyContent="center">
                      <Grid xs={12} sm={4}>
                        {isLoading ? (
                          <Skeleton />
                        ) : (
                          <Select
                            color="secondary"
                            displayEmpty
                            fullWidth
                            id="card-product-amount-select"
                            inputProps={{ 'aria-label': 'amount' }}
                            labelId="card-product-amount-select-label"
                            name="amount"
                            onChange={formik.handleChange}
                            size="small"
                            value={formik.values.amount}
                            disabled={!denominationAvailable}
                          >
                            <MenuItem value="">Amount</MenuItem>
                            {cardProduct?.denominationAvailable.map((denominationValue) => (
                              <MenuItem key={denominationValue} value={denominationValue}>
                                {formatCentsToDollars(denominationValue)}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </Grid>
                      <Grid xs={12} sm={4}>
                        <Button
                          fullWidth
                          id="card-product-buy-button"
                          variant="contained"
                          type="submit"
                          disabled={!denominationAvailable || !formik.isValid}
                        >
                          Buy
                        </Button>
                      </Grid>
                    </Grid>
                    <Box display="flex" justifyContent="center" alignItems="center">
                      {formik.errors.amount ? <Alert severity="error">{formik.errors.amount}</Alert> : null}
                    </Box>
                  </>
                )}
              </form>
            )}
          </Stack>
          <Stack sx={{ p: 2 }}>
            {cardProduct?.displayDescription && (
              <Typography variant="body1" sx={{ pb: 2, whiteSpace: 'pre-wrap' }}>
                {cardProduct.displayDescription}
              </Typography>
            )}
            <Typography variant="h5" color="secondary">
              Important information
            </Typography>
            <List sx={{ listStyleType: 'disc', pl: 3 }}>
              <BulletListItem>Increments based on available balance or merchant max.</BulletListItem>
              <BulletListItem>May be used in store and online (refer to merchant terms).</BulletListItem>
              <BulletListItem>Each cards terms are merchant specific.</BulletListItem>
              <BulletListItem>Once purchased, cards are non-refundable and treated like cash.</BulletListItem>
              <BulletListItem>Cards are non-reloadable and can be used up to the amount loaded.</BulletListItem>
              {cardProduct?.activationDelayDuration && (
                <BulletListItem>
                  <DelayedActivationText duration={cardProduct.activationDelayDuration} />
                </BulletListItem>
              )}
              <BulletListItem>
                <Link color="secondary" underline="always" onClick={() => handleDialogOpen('content')}>
                  Terms & conditions of use
                </Link>
              </BulletListItem>
            </List>
          </Stack>
          {(productDescription || productRedemptionInfo) && (
            <Accordion sx={{ backgroundColor: 'grey.100' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5" color="secondary">
                  Card specific information
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack>
                  {productDescription && (
                    <>
                      <Typography variant="subtitle1" color="secondary">
                        Description
                      </Typography>
                      <Typography
                        dangerouslySetInnerHTML={{ __html: productDescription.replace(/[^\x00-\x7F]/g, '') }}
                        fontSize="small"
                        py={1}
                        variant="body1"
                        whiteSpace="pre-wrap"
                      />
                    </>
                  )}
                  {productRedemptionInfo && (
                    <>
                      <Typography variant="subtitle1" color="secondary" pt={2}>
                        Redemption
                      </Typography>
                      <Typography
                        dangerouslySetInnerHTML={{ __html: productRedemptionInfo.replace(/[^\x00-\x7F]/g, '') }}
                        fontSize="small"
                        py={1}
                        variant="body1"
                        whiteSpace="pre-wrap"
                      />
                    </>
                  )}
                </Stack>
              </AccordionDetails>
            </Accordion>
          )}
        </CardContent>
      </Card>
      <ShopDialog
        title="Terms & conditions of use"
        content={cardProduct?.productTnCText?.replace(/[^\x00-\x7F]/g, '')}
        iFrameUrl={cardProduct?.productTnCUrl}
        open={dialogProps.open}
        onClose={handleDialogClose}
      />
      <Toast />
    </Stack>
  );
};

export default CardProduct;
