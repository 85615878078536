import { Box, CircularProgress, Stack } from '@mui/material';
import Logo from 'components/logo/logo';
import './loader.scss';

export const Loader = (): JSX.Element => (
  <Box flex="1 1 auto" display="flex" alignItems="center" justifyContent="center" margin={1}>
    <CircularProgress variant="indeterminate" />
  </Box>
);

export const PageLoader = (): JSX.Element => (
  <Stack spacing={2} sx={{ flex: '1 1 auto', alignItems: 'center', justifyContent: 'center' }}>
    <Logo type="square" />
    <CircularProgress variant="indeterminate" />
  </Stack>
);
