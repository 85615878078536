/* eslint-disable @typescript-eslint/no-unused-vars */
import { CssBaseline } from '@mui/material';
import * as Sentry from '@sentry/react';
import { Helmet } from 'react-helmet-async';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { routes } from 'routes';

function App(): JSX.Element {
  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

  const router = sentryCreateBrowserRouter(routes);

  return (
    <>
      <Helmet titleTemplate="%s - PressPay" defaultTitle="PressPay">
        <meta name="description" content="PressPay web app" />
      </Helmet>
      <CssBaseline />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
