import { CircularProgress } from '@mui/material';
import { FC, ReactElement } from 'react';

interface ButtonSpinnerProps {
  loading: boolean;
}

const ButtonSpinner: FC<ButtonSpinnerProps> = ({ loading }: ButtonSpinnerProps): ReactElement =>
  loading ? <CircularProgress size="1rem" sx={{ color: 'inherit', ml: 1 }} /> : null;

export default ButtonSpinner;
