import { activationDelayDurationInHours } from 'lib/models/card-product';

export const DelayedActivationText = (props: { duration?: string }): JSX.Element => {
  const delayHours = activationDelayDurationInHours(props.duration);
  return (
    <>
      This card has a&nbsp;
      <strong>
        delayed activation period
        {delayHours && (
          <>
            &nbsp;of up to&nbsp;
            {delayHours} hours
          </>
        )}
      </strong>
      &nbsp;from the time of purchase before you can use it.
    </>
  );
};
