import { Action, combineReducers, configureStore, createAction, ThunkAction } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { userWebApi } from 'api';
import { pick } from 'lodash';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  purgeStoredState,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { authReducer } from './auth';
import { profileReducer } from './profile';
import { transactionReducer } from './transaction';

const authPersistConfig = { key: 'auth', storage };

export const resetApp = createAction('resetApp');

const appReducer = combineReducers({
  // Store auth state in session storage
  auth: persistReducer(authPersistConfig, authReducer),
  profile: profileReducer,
  transactions: transactionReducer,
  [userWebApi.reducerPath]: userWebApi.reducer,
});

const rootReducer = (state, action) => {
  if (action?.type === resetApp.type) {
    // Clear persisted states
    purgeStoredState(authPersistConfig);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return appReducer(pick(state, ['auth._persist']) as any, action);
  }

  return appReducer(state, action);
};

export const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(userWebApi.middleware),
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof appReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action<string>>;

setupListeners(store.dispatch);
