/* eslint-disable */
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useAppDispatch } from 'lib/hooks';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import RepaymentDefault from '../../components/repayment/repayment-default';
import { RepaymentLoading } from '../../components/repayment/repayment-loading';
import { updateLoadingFlag, useProfileState } from '../../lib/store/profile';

const DebitCardRepaymentSuccess = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // To prevent user from navigating to this page via uri directly
  if (!location.state?.shouldDisplay) {
    navigate('/not-found');
  }

  useEffect(() => {
    dispatch(updateLoadingFlag(true));
  }, []);

  const {
    data: { balanceAvailable },
    isLoading: isProfileLoading,
  } = useProfileState();

  if (isProfileLoading) {
    return (
      <>
        <Helmet title="Repayment" />
        <RepaymentLoading />
      </>
    );
  }

  return (
    <>
      <Helmet title="Repayment" />
      <Grid container sx={{ flex: '1 1 auto', gap: 4, my: { sm: 4 } }}>
        <RepaymentDefault balanceAvailable={balanceAvailable} title="Debit card repayment successful" />
      </Grid>
    </>
  );
};

export default DebitCardRepaymentSuccess;
