import { bankReconnectionEnabled, bankReconnectionRequired } from 'lib/models/user';
import { useProfileState } from 'lib/store/profile';

export const useBankConnection = (): { reconnectionEnabled: boolean; reconnectionRequired: boolean } => {
  const { data } = useProfileState();
  return {
    reconnectionEnabled: bankReconnectionEnabled(data),
    reconnectionRequired: bankReconnectionRequired(data),
  };
};
