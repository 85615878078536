/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { useAppSelector } from 'lib/hooks';
import { forgotEmail, forgotEmailBuilder } from './forgot-email';
import { verifyEmail, verifyEmailBuilder } from './verify-email';

export interface AuthState {
  authenticated: boolean;
  canChangePassword: boolean;
  groups?: string[];
  continuePath?: string;
  verificationCountdown?: number;
  error?: string;
}

export const initialState: AuthState = {
  authenticated: false,
  canChangePassword: false,
  verificationCountdown: 0,
};

export interface AuthUserSession {
  signInUserSession?: {
    accessToken?: {
      payload?: Record<string, unknown>;
    };
  };
}

export const getAccessToken = createAsyncThunk('auth/accessTokenRefresh', async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return JSON.parse(JSON.stringify(user)) as AuthUserSession;
  } catch (err) {
    return undefined;
  }
});

const groupsFromSession = (auth: AuthUserSession) =>
  (auth?.signInUserSession?.accessToken?.payload?.['cognito:groups'] ?? []) as string[];

const CONTINUE_EXCLUDED_PATHS = ['/shop/order/create', '/shop/order/process'];

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.authenticated = true;
      state.groups = groupsFromSession(action.payload);
      state.error = undefined;
    },
    setCanChangePassword: (state, action) => {
      state.canChangePassword = action.payload;
    },
    setContinuePath: (state, action) => {
      state.continuePath = CONTINUE_EXCLUDED_PATHS.includes(action.payload) ? '/' : action.payload;
    },
    setAuthError: (state, action) => {
      state.error = action.payload;
    },
    resetAuth: (state) => ({
      ...initialState,
      continuePath: state.continuePath,
    }),
    setVerificationCountdown: (state, action) => {
      state.verificationCountdown = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder = forgotEmailBuilder(builder);
    builder = verifyEmailBuilder(builder);
    return builder.addCase(getAccessToken.fulfilled, (state, action) => {
      state.groups = groupsFromSession(action.payload);
    });
  },
});

export { forgotEmail, verifyEmail };
export const {
  actions: { authenticated, setCanChangePassword, setAuthError, setContinuePath, resetAuth, setVerificationCountdown },
  reducer: authReducer,
} = authSlice;
export const useAuthState = (): AuthState => useAppSelector<AuthState>((state) => state.auth);
