import { Button, Typography } from '@mui/material';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { ToastProps } from 'hooks/use-toast';
import { ApiError } from 'lib/error';
import { Link as RouterLink } from 'react-router-dom';

const ApiGatewayTimeoutError = 504;
type DomainError = 'CardProductAmountFeeError';
type StatusValues<T> = T extends { status: infer S } ? S : never;
type ErrorStatus = StatusValues<FetchBaseQueryError> | DomainError;

const defaultToastProps: ToastProps = {
  alertActionComponent: (
    <Button component={RouterLink} to={''} sx={{ marginLeft: 2 }} variant="text" color="secondary" reloadDocument>
      Refresh
    </Button>
  ),
  alertProps: {
    severity: 'error',
  },
  alertContent: <Typography>Something went wrong.</Typography>,
};

export const cardProductInActiveErrorToastProps: ToastProps = {
  alertActionComponent: (
    <Button
      component={RouterLink}
      to={'/shop/browse'}
      sx={{ marginLeft: 2, textAlign: 'center' }}
      variant="text"
      color="secondary"
      reloadDocument
    >
      Browse cards
    </Button>
  ),
  alertProps: {
    severity: 'error',
  },
  alertContent: (
    <Typography>
      Sorry, the information about the card you are attempting to purchase has been updated. Please select the card from
      the catalogue to try again.
    </Typography>
  ),
};

export const canWithdrawErrorToastProps = (closeToast: () => void): ToastProps => ({
  alertActionComponent: (
    <Button sx={{ marginLeft: 2 }} variant="text" color="secondary" onClick={closeToast}>
      Close
    </Button>
  ),
  alertProps: {
    severity: 'error',
  },
  alertContent: (
    <Typography>
      You can continue to browse cards, but you won&#39;t be able to withdraw due to your current account status. When
      this changes, we&#39;ll let you know right away.
    </Typography>
  ),
});

export const errorToastProps: Record<ErrorStatus, ToastProps> = {
  CardProductAmountFeeError: {
    alertActionComponent: (
      <Button component={RouterLink} to={''} sx={{ marginLeft: 2 }} variant="text" color="secondary" reloadDocument>
        Refresh
      </Button>
    ),
    alertProps: {
      severity: 'error',
    },
    alertContent: <Typography>Something went wrong.</Typography>,
  },
  [ApiGatewayTimeoutError]: {
    alertActionComponent: (
      <Button component={RouterLink} to={''} sx={{ marginLeft: 2 }} variant="text" color="secondary" reloadDocument>
        Refresh
      </Button>
    ),
    alertProps: {
      severity: 'error',
    },
    alertContent: <Typography>Your request timed out. Please reload the page and try again.</Typography>,
  },
  FETCH_ERROR: defaultToastProps,
  CUSTOM_ERROR: defaultToastProps,
  PARSING_ERROR: defaultToastProps,
  TIMEOUT_ERROR: defaultToastProps,
};

export const hasErrorType = (errors: ApiError[], errorType: string): boolean =>
  errors.some((e) => e.meta?.errorType === errorType);

export const mapErrorToToastProps = (
  errorStatus: ErrorStatus,
  errors: ApiError[],
  options?: {
    errorTypeToMatch?: DomainError;
    displayAllErrors?: boolean;
  },
): ToastProps => {
  const { errorTypeToMatch, displayAllErrors } = options ?? {
    errorTypeToMatch: undefined,
    displayAllErrors: undefined,
  };

  // Display error matching type.
  if (errorTypeToMatch && hasErrorType(errors, errorTypeToMatch)) {
    return errorToastProps[errorTypeToMatch] ?? defaultToastProps;
  }

  // Display all returned errors.
  if (displayAllErrors) {
    return {
      alertActionComponent: (
        <Button component={RouterLink} to={''} sx={{ marginLeft: 2 }} variant="text" color="secondary" reloadDocument>
          Refresh
        </Button>
      ),
      alertProps: {
        severity: 'error',
      },
      alertContent: (
        <>
          {errors.map(({ title }) => (
            <Typography key={title}>{title}</Typography>
          ))}
        </>
      ),
    };
  }

  // Display error matching HTTP status code.
  return errorToastProps[errorStatus] ?? defaultToastProps;
};
