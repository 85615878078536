import { Card, Skeleton, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

interface Props {
  header?: string;
}

export const RepaymentLoading: React.FC<Props> = (props: Props) => (
  <Grid container sx={{ flex: '1 1 auto', gap: 4, my: { sm: 4 } }}>
    <Grid xs={12}>
      <Card>
        <Grid container gap={2} p={2}>
          <Grid xs={12}>
            <Typography variant="h5" color="secondary" textAlign="center">
              {props.header ?? <Skeleton />}
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Typography variant="h3" color="secondary" textAlign="center">
              <Skeleton />
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Typography color="secondary" textAlign="center">
              <Skeleton />
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Typography color="secondary" textAlign="center">
              <Skeleton />
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  </Grid>
);
