import { Alert, AlertProps, Snackbar, SnackbarProps } from '@mui/material';
import { ReactNode, useCallback, useState } from 'react';

export interface ToastProps {
  isOpen?: boolean;
  alertActionComponent?: JSX.Element;
  snackBarProps?: SnackbarProps;
  alertProps?: AlertProps;
  alertContent?: ReactNode;
}

const useToast = (
  props?: ToastProps,
): [() => JSX.Element, (openProps?: Omit<ToastProps, 'isOpen'>) => void, () => void] => {
  const {
    isOpen: isOpenProp = false,
    alertActionComponent: alertActionComponentProp = null,
    snackBarProps: snackBarPropsProp = {},
    alertProps: alertPropsProp = {},
    alertContent: alertContentProp = null,
  } = props;

  const [isOpen, setIsOpen] = useState(isOpenProp);
  const [snackBarProps, setSnackBarProps] = useState(snackBarPropsProp);
  const [alertProps, setAlertProps] = useState(alertPropsProp);
  const [alertContent, setAlertContent] = useState(alertContentProp);
  const [alertActionComponent, setAlertActionComponent] = useState(alertActionComponentProp);

  const open = useCallback((openProps?: Omit<ToastProps, 'isOpen'>) => {
    setIsOpen(true);
    setAlertActionComponent(openProps.alertActionComponent ?? null);
    setSnackBarProps(openProps?.snackBarProps ?? {});
    setAlertProps(openProps?.alertProps ?? {});
    setAlertContent(openProps?.alertContent ?? null);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const Toast = useCallback(() => {
    return (
      <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }} open={isOpen} {...snackBarProps}>
        <Alert onClose={close} action={alertActionComponent} sx={{ width: '100%' }} {...alertProps}>
          {alertContent}
        </Alert>
      </Snackbar>
    );
  }, [isOpen, snackBarProps, close, alertActionComponent, alertProps, alertContent]);

  return [Toast, open, close];
};

export default useToast;
