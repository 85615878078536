import apiClient from 'lib/api-client';
import { UserAttributes, TransactionRecord, Withdrawal } from 'lib/models';
//
// Note: API Error handling should be done in apiClient
//

export const fetchProfile = async (): Promise<UserAttributes> =>
  apiClient.get<{ data: { attributes: UserAttributes } }>('/user/account').then((res) => res.data.data.attributes);

export const postForgotEmail = async (mobileNumber: string, recaptchaToken: string): Promise<AccountResponse> =>
  apiClient
    .post<{ data: { attributes: AccountResponse } }>('/auth/forgot-email', undefined, {
      headers: { 'x-recaptcha-token': recaptchaToken },
      params: { mobileNumber: mobileNumber.replace(/^0?(.)/, (_, a: string) => `+61${a}`) },
    })
    .then((res) => res.data.data.attributes);

interface AccountRequest {
  email: string;
  recaptchaToken: string;
}

export interface AccountResponse {
  accountExists: boolean;
  accountCreated?: boolean;
  emailMasked?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const findOrCreateAccount = async (props: AccountRequest): Promise<AccountResponse> => {
  const { email, recaptchaToken } = props;
  return apiClient
    .post<{ data: { attributes: AccountResponse } }>(`/auth/account`, null, {
      params: { email },
      headers: { 'x-recaptcha-token': recaptchaToken },
    })
    .then((res) => res.data.data.attributes);
};

export interface ValidateSocialEmail {
  cogAccountPreExisting: boolean;
  cogAccountCreated: boolean;
  ppAccountExists: boolean;
}

interface CheckSocialUserInDb {
  ppAccountExists: boolean;
  emailVerified: boolean;
  socialEmail: string;
}
export const checkSocialUserInDb = async (props: {
  uwaToken: string;
  tokenProvider: string;
}): Promise<CheckSocialUserInDb> => {
  const { uwaToken, tokenProvider } = props;
  return apiClient.post('/social/validate', { uwaToken, tokenProvider });
};

export const fetchTransactions = async (): Promise<TransactionRecord[]> =>
  apiClient.get<{ data: TransactionRecord[] }>('/user/transactions').then((res) => res.data.data);

export const postWithdrawal = async ({ amount }: { amount: number }): Promise<Withdrawal> =>
  apiClient.post(`/user/transactions`, { amount }).then((res) => res?.data?.data?.attributes as Withdrawal);

export const resetPassword = async (props: { email: string }): Promise<boolean> => {
  const { email } = props;
  await apiClient.post(`/auth/reset-password`, {
    data: { attributes: { email } },
  });
  return true;
};

export const fetchApiToken = async (): Promise<string> =>
  apiClient.get(`/user/token`).then((res) => res.data.data.attributes.token as string);
