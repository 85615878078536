import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles/createPalette' {
  type PressPayPalette = PaletteColorOptions & {
    apple: string;
    facebook: string;
    google: string;
  };

  interface Palette {
    pp: PressPayPalette;
  }

  interface PaletteOptions {
    pp: PressPayPalette;
  }
}

const palette = {
  background: {
    default: '#fafafa',
  },
  primary: {
    main: '#ff0094',
  },
  secondary: {
    main: '#001E5A',
  },
  error: {
    main: '#B3261E',
    light: '#F9DEDC',
  },
  pp: {
    apple: '#000000',
    facebook: '#4267B2',
    gold: '#FFAE00',
    google: '#4285F4',
  },
};

const typography = {
  fontFamily: ['Lato', 'Helvetica', 'Arial', 'sans-serif'].join(','),
};

const components = {
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: '10rem',
      },
    },
  },
};

const baseTheme = createTheme({
  components,
  typography,
  palette,
});

const theme = createTheme(baseTheme, {});

export default theme;
