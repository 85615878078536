import { Card, Typography, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import sittingMan from 'media/sitting-man.svg';

interface Props {
  balanceAvailable: number;
  title: string;
}

const RepaymentDefault = ({ balanceAvailable, title }: Props): JSX.Element => {
  const hasAvailableBalance = balanceAvailable > 0;
  return (
    <Grid xs={12}>
      <Card>
        <Grid container gap={2} p={2} display="flex">
          <Grid xs={12}>
            <Typography variant="h5" textAlign="center">
              {title}
            </Typography>
          </Grid>
          <Grid xs={12} justifyContent="center" alignItems="center" display="flex">
            <img src={sittingMan} alt="sitting man" height="200px" />
          </Grid>
          <Grid xs={12}>
            <Typography textAlign="center">
              {hasAvailableBalance
                ? `You have $${balanceAvailable} available to withdraw.`
                : `Your repayment details will appear here when there is an outstanding balance on your account.`}
            </Typography>
          </Grid>
          {hasAvailableBalance && (
            <Grid xs={12} container spacing={2}>
              <Grid xs={12} sm={6}>
                <Button variant="contained" href="/withdraw" fullWidth>
                  Withdraw using Advance
                </Button>
              </Grid>
              <Grid xs={12} sm={6}>
                <Button color="secondary" fullWidth href="/shop/browse" id="goto-shop-buy-btn" variant="outlined">
                  Withdraw using Shop
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Card>
    </Grid>
  );
};

export default RepaymentDefault;
