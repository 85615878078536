import { Alert, AlertTitle, Card, CardContent, Stack, Typography } from '@mui/material';
import CognitoIdentifierWidget from 'components/cognito-identifier-widget';
import Logo from 'components/logo/logo';
import { useAppDispatch } from 'lib/hooks';
import { setContinuePath, useAuthState } from 'lib/store/auth';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Login = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { error } = useAuthState();

  const continuePath = location.state?.continuePath ?? null;

  useEffect(() => {
    if (continuePath) {
      dispatch(setContinuePath(continuePath));
    }
  }, [dispatch, continuePath]);

  return (
    <Card sx={{ flex: '1 1 auto', maxWidth: 448, mx: 'auto' }}>
      <CardContent sx={{ p: { xs: 2, sm: 4 } }}>
        <Stack sx={{ gap: 2 }}>
          <Logo type="square" />
          <Typography textAlign="center" variant="h5">
            Sign in
          </Typography>
          <Typography textAlign="center">to continue to PressPay</Typography>

          {continuePath !== '/' && (
            <Typography textAlign="center" color={'error'}>
              Your session has expired.
            </Typography>
          )}

          {error && (
            <Alert severity="error">
              <AlertTitle>Sign in error</AlertTitle>
              {error}
            </Alert>
          )}
          <CognitoIdentifierWidget />
          {/*
          // Until we can verify the social account, disable this feature
          <Divider variant="middle" flexItem sx={{ my: 2 }}>
            <Typography variant="subtitle1">or</Typography>
          </Divider>
          <SocialIdentifierWidget />
          */}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Login;
