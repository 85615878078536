import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Helmet } from 'react-helmet-async';
import { EmploymentDetails as Employment } from '../../components/employment-details';

const EmploymentDetails: React.FC = (): JSX.Element => {
  return (
    <>
      <Helmet title="Bank Reconnection" />
      <Grid container sx={{ flex: '1 1 auto', gap: 4, my: { sm: 4 } }}>
        <Employment />
      </Grid>
    </>
  );
};

export default EmploymentDetails;
