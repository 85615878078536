import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { template } from 'lodash';

export interface ApiError {
  code: string;
  status: number;
  title: string;
  meta?: {
    errorData: unknown;
    errorType: string;
  };
}

export const isFetchError = (error: FetchBaseQueryError | SerializedError | undefined): error is FetchBaseQueryError =>
  error && 'status' in error;

export const errorsFromResponse = (error: FetchBaseQueryError | SerializedError): ApiError[] => {
  if ('status' in error && error.data) {
    const { errors = [] } = error.data as { errors?: ApiError[] };
    return errors;
  }

  return [];
};

const ERROR_MESSAGES = {
  DefaultError: 'Something went wrong.',
  DefaultWithdrawalError: "Couldn't complete your withdrawal. Please try again later.",
  MaxBalanceCreditError: `It looks like you have reached your withdrawal limit for this pay cycle. Try again after <%= incomeNextDate %>`,
  RepaymentCoolDownError:
    "It looks like we're still finalising your recent transaction. Please check back in a few minutes.",
} as const;

export type ErrorMessages = typeof ERROR_MESSAGES;

export const mapErrorCodeToMessage = (
  errorCode: string,
  args: object | undefined = {},
  defaultMessageKey: keyof ErrorMessages = 'DefaultError',
): string => {
  const errorMessageTemplate = template(ERROR_MESSAGES[errorCode] ?? ERROR_MESSAGES[defaultMessageKey]);
  return errorMessageTemplate(args);
};
