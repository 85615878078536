/* eslint-disable @typescript-eslint/no-unsafe-return */
import { CardOrder, CardOrderReceipt } from '../lib/models/card-order';
import { CardProduct } from '../lib/models/card-product';
import { userWebApi } from '.';

export const shopApi = userWebApi.injectEndpoints({
  endpoints: (build) => ({
    listCardProducts: build.query<CardProduct[], void>({
      query: () => ({
        url: '/shop/card-product',
        method: 'GET',
      }),
      transformResponse: (response: { data: { id: string; attributes: CardProduct }[] }) =>
        response?.data?.map((d) => ({ id: d.id, ...d.attributes })),
    }),
    getCardProduct: build.query<CardProduct, string>({
      query: (id: string) => ({
        url: `/shop/card-product/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: { data: { id: string; attributes: CardProduct } }) => ({
        id: response?.data?.id,
        ...response?.data?.attributes,
      }),
    }),
    createCardOrder: build.mutation<
      CardOrder,
      { verificationCode: string } & Pick<CardOrder, 'amount' | 'amountFee' | 'cardProductId'>
    >({
      query: (order) => ({
        url: '/shop/card-order',
        method: 'POST',
        body: { data: { attributes: order } },
      }),
      transformResponse: (response: { data: { id: string; attributes: CardOrder } }) => ({
        id: response?.data?.id,
        ...response?.data?.attributes,
      }),
    }),
    getCardOrderReceipt: build.query<CardOrderReceipt, string>({
      query: (orderId) => ({
        url: `/shop/card-order/${orderId}/receipt`,
        method: 'GET',
      }),
      transformResponse: (response: { data: { attributes: CardOrderReceipt } }) => response?.data?.attributes,
    }),
    getCardOrderStatus: build.query<Pick<CardOrder, 'id' | 'status'>, string>({
      query: (orderId) => ({
        url: `/shop/card-order/${orderId}/status`,
        method: 'GET',
      }),
      transformResponse: (response: { data: { attributes: Pick<CardOrder, 'id' | 'status'> } }) =>
        response?.data?.attributes,
    }),
  }),
});

export const {
  useListCardProductsQuery,
  useGetCardProductQuery,
  useGetCardOrderReceiptQuery,
  useGetCardOrderStatusQuery,
  useCreateCardOrderMutation,
} = shopApi;
