import { Box, Container } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './main-footer';

interface BlackLayoutProps {
  children?: ReactNode;
}

const BlankLayout: FC<BlackLayoutProps> = ({ children }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
    <Container
      disableGutters
      sx={{ display: { xs: 'flex', sm: 'block' }, flex: '1 0 auto', p: { sm: 4 } }}
      maxWidth="md"
    >
      {children || <Outlet />}
    </Container>
    <Footer />
  </Box>
);

export default BlankLayout;
