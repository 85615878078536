const config = {
  AMPLIFY_DOMAIN: process.env.REACT_APP_AMPLIFY_DOMAIN,
  API_URL: process.env.REACT_APP_API_URL,
  COGNITO_CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID,
  COGNITO_REGION: process.env.REACT_APP_COGNITO_REGION,
  COGNITO_USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  SIGN_UP_DOMAIN: process.env.REACT_APP_SIGN_UP_DOMAIN,
  PAYMENT_CHECKOUT_STATIC_KEY: process.env.REACT_APP_PAYMENT_CHECKOUT_STATIC_KEY,
  ENV: process.env.ENV,
};

export default config;
