/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { findOrCreateAccount } from 'lib/api';
import type { AuthState } from './index';

export interface VerifyUserProps {
  email: string;
  recaptchaToken: string;
}

export const verifyEmail = createAsyncThunk('auth/verifyEmail', async (props: VerifyUserProps, { dispatch }) =>
  findOrCreateAccount(props),
);

export const verifyEmailBuilder = (builder: ActionReducerMapBuilder<AuthState>): ActionReducerMapBuilder<AuthState> =>
  builder;
