export const copy = {
  appName: 'UWA',
  nav: {
    home: 'Home',
    profile: 'My Profile',
    withdraw: 'Withdraw',
    logout: 'Logout',
    login: 'Login',
    support: 'Support',
    termsAndConditions: 'Terms & Conditions',
    privacyPolicy: 'Privacy Policy',
    complaintsPolicy: 'Complaints Policy',
    targetMarketDetermination: 'Target Market Determination',
    policies: 'Policies',
  },
  accountStatus: {
    active: 'Active',
    deleted: 'Closed',
    dishonoured: 'Closed',
    inactive: 'On hold',
    on_hold: 'On hold',
    rejected: 'Declined',
    suspended: 'Suspended',
  },
  accountStatusReason: {
    continuous_risk_passed: 'Assessment criteria not met',
    continuous_risk_failed_binary: 'Assessment criteria not met',
    continuous_risk_failed_spectrum: 'Assessment criteria not met',
    continuous_risk_no_primary_bank_account: 'Invalid bank account',
    continuous_risk_bank_data_unavailable: 'Bank data unavailable',
    continuous_risk_bank_data_outdated: 'Bank data unavailable',
    continuous_risk_unknown_error: 'Assessment criteria not met',
    bank_data_unavailable: 'Bank data unavailable',
  },
  errors: {
    ApiTimeout: 'Your connection is slow or broken. Please try again shortly',
    ApiUnavailable: 'PressPay Offline',
    AuthError: 'Error Authenticating to PressPay servers',
    CoolDownError: 'Cant withdraw until cool down period has expired',
    FailedNewUserForgotPassword: 'Failed to create user account. Please contact support',
    InvalidCogAccount: 'There is a problem with your account, please contact support',
    LimitExceededException:
      'You have attempted to change your account too many times. Please wait a few minutes and try again',
    LogoutFailed: 'Logout Failed',
    NoAuthCookies: "We couldn't set/read Cookies. Please make sure you allow cookies",
    NotAuthorizedException: 'Incorrect password',
    UnknownApiError: 'Unknown API Error',
    UnknownError: 'Unknown Error. Please try again or contact support',
    UnknownWithdrawalError: 'Unknown Withdrawal Error',
    UserNotActive: 'Withdrawal failed as your account is not active',
    ValidationError: 'Data Validation Error',
    RefreshJwtError: 'Failed to authenticate to PressPay servers. Logging you out.',
  },
  notices: {
    reminderSent: 'Check your email for instructions on how to logo on',
    WithdrawalSuccess: 'Your withdrawal was successful',
    ChangePasswordSuccess: 'Your password has been successfully changes',
  },
  emails: {
    support: 'PressPay<support@presspay.com.au>',
  },
  urls: {
    assessmentCriteriaLink:
      'https://presspay.zendesk.com/hc/en-au/articles/900006019586-What-is-PressPay-s-assessment-criteria-',
    zendesk: 'https://presspay.zendesk.com/hc/en-au',
    termsOfService: 'https://presspay.com.au/terms-of-service/',
    privacyPolicy: 'https://presspay.com.au/privacy-policy/',
    complaintsPolicy: 'https://presspay.com.au/complaints-policy/',
    marketDetermination: 'https://presspay.com.au/wp-content/uploads/2021/11/PressPay-Target-Market-Determination.pdf',
    signUp: 'https://sign-up.presspay.com.au/',
  },
  comp: {
    completeReset: {
      newAccount:
        'Welcome to the User Web App, as this is the first time you have logged on, we need you to set your password. An email has been sent with instructions on how to do this',
      success:
        'Check your email for a password reset confirmation message from us. If you cant find our email, you can contact support for help.',
    },
    requestEmailReminder: {
      instructions:
        'If you have forgotten your email address we can send you a reminder. Enter you mobile below and if we can find your account, an email will be sent with login instructions',
    },
    profileWidget: {
      labels: {
        firstName: 'First Name',
        middleName: 'Middle Name',
        lastName: 'Last Name',
        email: 'Email',
        mobileNumber: 'Mobile',
        prettyDob: 'Date of Birth',
      },
    },
  },
};
