import { Box, Divider, Link, Stack, Typography } from '@mui/material';
import { copy } from 'lib/copy';
import { DateTime } from 'luxon';
import presspayLogoWhite from 'media/presspay-logo-white.svg';
import type { FC } from 'react';

const color = 'secondary.contrastText';

// get current year

const Footer: FC = (props) => (
  <Box sx={{ backgroundColor: 'grey.900', color, p: 2, flexShrink: 0 }} {...props}>
    <Stack>
      <Stack sx={{ justifyContent: 'center', alignItems: 'center', gap: 2, p: 2 }}>
        <img height={30} alt="PressPay" src={presspayLogoWhite} />
      </Stack>
      <Stack justifyContent="center" direction="row" sx={{ gap: 2 }}>
        <Link color={color} fontSize="small" underline="hover" href={copy.urls.zendesk} target="_blank">
          Support
        </Link>
        <Divider color={color} orientation="vertical" variant="middle" flexItem />
        <Link color={color} fontSize="small" underline="hover" href={copy.urls.termsOfService} target="_blank">
          Terms of Service
        </Link>
        <Divider color={color} orientation="vertical" variant="middle" flexItem />
        <Link color={color} fontSize="small" underline="hover" href={copy.urls.privacyPolicy} target="_blank">
          Privacy Policy
        </Link>
      </Stack>
      <Typography textAlign="center" color={color} sx={{ mt: 1 }} variant="caption">
        © {DateTime.now().year} PressPay. All rights reserved.
      </Typography>
      <Typography textAlign="center" fontSize={9} variant="caption">
        This site is protected by reCAPTCHA and the Google&nbsp;
        <Link color={color} href="https://policies.google.com/privacy">
          Privacy Policy
        </Link>
        &nbsp;and&nbsp;
        <Link color={color} href="https://policies.google.com/terms">
          Terms of Service
        </Link>
        &nbsp;apply.
      </Typography>
    </Stack>
  </Box>
);

export default Footer;
