/* eslint-disable @typescript-eslint/no-misused-promises */

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { DatePicker } from '@mui/x-date-pickers';
import { userApi } from 'api/user';
import ButtonSpinner from 'components/button-spinner';
import useToast from 'hooks/use-toast';
import { Income, employmentType, incomeFrequency } from 'lib/models/income';
import { primaryBankAccountChangeStatus } from 'lib/models/profile-change-state';
import { useProfileState } from 'lib/store/profile';
import { range } from 'lodash';
import { DateTime } from 'luxon';
import { FC, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useBankConnection } from '../../hooks/use-bank-connection';

const sydneyTimeZone = 'australia/sydney';

type EmploymentDetailAttributes = Omit<Income, 'lastDate'> & {
  lastDate: DateTime;
  bankAccountChangeStatus: (typeof primaryBankAccountChangeStatus)[number];
};

const LoadingSkeleton: FC = (): JSX.Element => (
  <Grid container xs={12} justifyContent="center">
    <Grid xs={12}>
      {range(5).map((i) => (
        <Typography key={i} variant="h2">
          <Skeleton animation="pulse" />
        </Typography>
      ))}
    </Grid>
  </Grid>
);

export const EmploymentDetails: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const [Toast, openToast, closeToast] = useToast({ isOpen: false });
  const { data: income, isLoading, isError, isSuccess } = userApi.useGetIncomeQuery();

  const acknowledgeError = useCallback(() => {
    closeToast();
    navigate('/');
  }, [closeToast, navigate]);

  useEffect(() => {
    if (isError) {
      openToast({
        alertActionComponent: (
          <Button sx={{ marginLeft: 2 }} variant="text" color="secondary" onClick={acknowledgeError}>
            Close
          </Button>
        ),
        alertProps: { severity: 'error' },
        alertContent: 'Unable to fetch employment details. Please try again later.',
      });
    }
  }, [acknowledgeError, isError, openToast]);

  return (
    <>
      <Grid xs={12}>
        <Card>
          <Grid container gap={2} p={2} display="flex">
            <Grid xs={12}>
              <Typography variant="h5" textAlign="center">
                Employment details
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography textAlign="center">
                Please check that the details below are correct, or update them with your latest employment information.
              </Typography>
            </Grid>
            {isLoading && <LoadingSkeleton />}
            {isSuccess && <EmploymentDetailsForm income={income} />}
          </Grid>
        </Card>
      </Grid>
      <Toast />
    </>
  );
};

export const EmploymentDetailsForm: FC<{ income: Income }> = (props): JSX.Element => {
  const { reconnectionEnabled } = useBankConnection();
  const { income } = props;
  const {
    data: {
      bankAccount: { bankName, accountNumberLastDigits },
    },
  } = useProfileState();
  const [Toast, openToast, closeToast] = useToast({ isOpen: false });
  const [openBankAccountChangedDialog, setOpenBankAccountChangedDialog] = useState(false);
  const [update] = userApi.usePostEmploymentDetailsMutation();
  const navigate = useNavigate();

  const {
    control,
    formState,
    formState: { isSubmitting, dirtyFields },
    handleSubmit,
  } = useForm<EmploymentDetailAttributes>({
    defaultValues: {
      ...income,
      lastDate: DateTime.fromISO(income.lastDate).setZone(sydneyTimeZone),
      bankAccountChangeStatus: 'bankAccountUnchanged',
    },
  });

  const updateEmploymentDetails = useCallback(
    async ({
      bankAccountChangeStatus,
      lastAmount,
      lastDate,
      employerPayDescription,
      source,
      frequency,
    }: EmploymentDetailAttributes) => {
      await update({
        income: {
          ...(dirtyFields?.source && { source }),
          ...(dirtyFields?.employerPayDescription && { employerPayDescription }),
          ...(dirtyFields?.frequency && { frequency }),
          ...(dirtyFields?.lastAmount && { lastAmount: parseInt(lastAmount as unknown as string) }),
          ...(dirtyFields?.lastDate && {
            lastDate: DateTime.now()
              .set({ year: lastDate.year, month: lastDate.month, day: lastDate.day })
              .toUTC()
              .startOf('day')
              .toISO(),
          }),
        },
        bankAccount: { changeStatus: bankAccountChangeStatus },
      })
        .unwrap()
        .then(() => {
          switch (bankAccountChangeStatus) {
            case 'bankAccountUnchanged': {
              openToast({
                alertActionComponent: (
                  <Button sx={{ marginLeft: 2 }} variant="text" color="secondary" onClick={closeToast}>
                    Close
                  </Button>
                ),
                alertProps: { severity: 'success' },
                alertContent: 'Update successful!',
                snackBarProps: { autoHideDuration: 2000 },
              });
              break;
            }
            case 'bankAccountChanged': {
              setOpenBankAccountChangedDialog(true);
              break;
            }
            case 'bankChanged': {
              navigate('/bank-data');
              break;
            }
            default:
              break;
          }
        })
        .catch(() => {
          openToast({
            alertActionComponent: (
              <Button sx={{ marginLeft: 2 }} variant="text" color="secondary" onClick={closeToast}>
                Close
              </Button>
            ),
            alertProps: { severity: 'error' },
            alertContent: 'Unable to update employment details. Please try again later.',
          });
        });
    },
    [closeToast, dirtyFields, navigate, openToast, update],
  );

  const cancelEmploymentDetailsUpdate = useCallback(() => navigate('/'), [navigate]);

  const handleConfirm = useCallback(() => {
    setOpenBankAccountChangedDialog(false);
    navigate('/');
  }, [navigate]);

  return (
    <>
      <Grid component="form" container gap={2} onSubmit={handleSubmit(updateEmploymentDetails)}>
        <Grid xs={12}>
          <Controller
            name="source"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth variant="outlined">
                <InputLabel id="employment-type-label">Employment type</InputLabel>
                <Select
                  fullWidth
                  disabled={isSubmitting}
                  labelId="employment-type-label"
                  label="Employment type"
                  {...field}
                >
                  {Object.keys(employmentType).map((key) => (
                    <MenuItem key={key} value={key}>
                      {employmentType[key]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid xs={12}>
          <Controller
            name="frequency"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth variant="outlined">
                <InputLabel id="pay-frequency-label">Pay frequency</InputLabel>
                <Select
                  fullWidth
                  disabled={isSubmitting}
                  labelId="pay-frequency-label"
                  label="Pay frequency"
                  {...field}
                >
                  {Object.keys(incomeFrequency).map((key) => (
                    <MenuItem key={key} value={key}>
                      {incomeFrequency[key]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid xs={12}>
          <Controller
            name="lastAmount"
            control={control}
            rules={{
              required: 'Amount is required',
              pattern: {
                value: /^[0-9]\d*$/,
                message: 'Amount must be a number',
              },
              min: {
                value: 0,
                message: 'Amount must be positive',
              },
            }}
            render={({ field }) => (
              <TextField
                type="number"
                fullWidth
                error={!!formState.errors?.lastAmount}
                helperText={formState.errors?.lastAmount?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
                disabled={isSubmitting}
                label="Pay amount received"
                {...field}
              />
            )}
          />
        </Grid>
        <Grid xs={12}>
          <Controller
            name="lastDate"
            control={control}
            render={({ field }) => (
              <DatePicker
                sx={{ width: '100%' }}
                format="EEE, d MMM yyyy"
                maxDate={DateTime.now().setZone(sydneyTimeZone)}
                slotProps={{ textField: { fullWidth: true } }}
                disabled={isSubmitting}
                label="Last pay date"
                {...field}
              />
            )}
          />
        </Grid>
        <Grid xs={12}>
          <Controller
            name="employerPayDescription"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                placeholder="Optional"
                helperText="Optional: If your employer uses an employee number, e.g. “Acme Co Emp1234”, then use “Emp1234” as your pay
              description."
                inputProps={{ maxLength: 250 }}
                disabled={isSubmitting}
                label="Employer pay description"
                {...field}
              />
            )}
          />
        </Grid>
        {reconnectionEnabled && (
          <Grid xs={12}>
            <Controller
              name="bankAccountChangeStatus"
              control={control}
              render={({ field }) => (
                <FormControl component="fieldset" variant="standard">
                  <RadioGroup {...field}>
                    <FormControlLabel
                      value="bankAccountUnchanged"
                      control={<Radio />}
                      label={`I receive my regular pay into my ${bankName} account ending in ${accountNumberLastDigits}.`}
                      disabled={isSubmitting}
                    />
                    <FormControlLabel
                      value="bankAccountChanged"
                      control={<Radio />}
                      label={`My regular pay is received into a different bank account at ${bankName}.`}
                      disabled={isSubmitting}
                    />
                    <FormControlLabel
                      value="bankChanged"
                      control={<Radio />}
                      label="I need to change the bank where my regular pay is received."
                      disabled={isSubmitting}
                    />
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Grid>
        )}
        <Grid container xs={12} gap={2} justifyContent="center">
          <Grid xs={12} sm="auto">
            <Button type="submit" sx={{ alignSelf: 'center' }} fullWidth variant="contained" disabled={isSubmitting}>
              Update details <ButtonSpinner loading={isSubmitting} />
            </Button>
          </Grid>
          <Grid xs={12} sm="auto">
            <Button
              color="secondary"
              sx={{ alignSelf: 'center' }}
              fullWidth
              variant="outlined"
              onClick={cancelEmploymentDetailsUpdate}
              disabled={isSubmitting}
            >
              No update required
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <BankAccountChangedDialog open={openBankAccountChangedDialog} onConfirm={handleConfirm} />
      <Toast />
    </>
  );
};

const BankAccountChangedDialog = ({ open, onConfirm: handleConfirm }: { open: boolean; onConfirm: () => void }) => {
  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle sx={{ textAlign: 'center' }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <MailOutlineIcon fontSize="large" />
          <Typography variant="h6">Check your email inbox</Typography>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }}>
        <Typography variant="body1">Thanks for letting us know.</Typography>
        <Typography variant="body1">
          Please look out for an email we&apos;ve just sent you, and follow the instructions to change the bank account
          where your regular pay is received.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end' }}>
        <Button autoFocus color="secondary" onClick={handleConfirm}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
